import React, { useCallback, useEffect, useState } from 'react'
import { AiOutlineUserAdd } from 'react-icons/ai'
import AppointmentsTable from '../AppointmentsTable/AppointmentsTable';
import AppointmentModal from '../AppointmentModal/AppointmentModal';
import AppointmentMode from '../AppointmentMode/AppointmentMode';
import { NoDataFound, SearchInput } from '../../Common';
import { getAppointments } from '../../../utils/appointmentsUtils/appointmentsUtils';
import AppointmentTrackerModal from '../AppointmentTrackerModal/AppointmentTrackerModal';
import { useSelector } from 'react-redux';

const AppointmentsMainSection = () => {
  const user = useSelector((state) => state.userReducer.lab);
  const [selected, setSelected] = useState("New");
  const [open, setOpen] = useState(false);
  const [openTracker, setOpenTracker] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [editData, setEditData] = useState(null);
  const setAppointmentsFn = useCallback(() => {
    getAppointments(user?._id, selected).then(res => {setAppointments(res.data.data)}).catch(err => console.log(err));
  }, [selected, user?._id]);
  useEffect(() => {
    setAppointmentsFn();
  }, [setAppointmentsFn]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const filteredData = appointments?.filter((patient) => {
      const nameMatch = patient.name.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const pIdMatch = patient.patient_id.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const testMatch = patient.test_name.toString().toLowerCase()
        .includes(searchQuery.toLowerCase());
      return nameMatch || pIdMatch || testMatch ;
    });
    setFilteredData(filteredData);
  }, [appointments, searchQuery]);
            useEffect(() => {
              const intervalId = setInterval(() => {
                setAppointmentsFn();
              }, 5 * 60 * 1000);
              return () => clearInterval(intervalId);
            }, [setAppointmentsFn]);
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex font-semibold text-lg">Appointments</div>
        <div className="flex">
          <div className="flex">
            <SearchInput placeholder={"Search by Name, Id"} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          </div>
        </div>
      </div>
      {openTracker && <AppointmentTrackerModal setEditData={setEditData} editData={editData} setOpenTracker={setOpenTracker} setAppointmentsFn={setAppointmentsFn} />}    
      {open && <AppointmentModal user={user} setOpen={setOpen} editData={editData} setEditData={setEditData} setAppointmentsFn={setAppointmentsFn} />}
      <div className="flex  w-full h-full mt-5 justify-between items-center ">
        <AppointmentMode selected={selected} setSelected={setSelected} />
        <div className="flex">
          <button
            onClick={() => {
              setOpen(true);
            }}
            className="bg-secondary text-white   text-sm rounded-md flex gap-2 p-2 active:bg-secondary items-center"
          >
            <span>
              <AiOutlineUserAdd />
            </span>
            <span className="flex gap-1">Appointment</span>
          </button>
        </div>
      </div>
      {filteredData?.length > 0 ? (
        <AppointmentsTable datas={filteredData} setOpenTracker={setOpenTracker} setEditData={setEditData} setOpen={setOpen} setAppointmentsFn={setAppointmentsFn} />
      ) : (
        <div className="flex mt-10">
          <NoDataFound data={"appointments"} />
        </div>
      )}
    </>
  );
}

export default AppointmentsMainSection