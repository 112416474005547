export const validateLoginForm = (initialState, setErr) => {
    const passwordPattern = /^.{8,}$/

    if (!initialState.labId.trim()) {
        setErr({ global: "Invalid Lab Id" });
        return;
    }
    if (!passwordPattern.test(initialState.password)) {
        setErr({ global: "Invalid Password" });
        return;
    }
    return true;
}